import React, { useEffect, useState } from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import styles from "../styles/Unsubscribe.module.scss";
import { SettingsMap } from "../components/PageComponents/Dashboard/Account/NotificationSettings/utils";

const ConfirmationImage =
  "https://imgix.cosmicjs.com/2702a190-febd-11eb-aaf9-c7d10ae67fc2-ThumbsUpBidFoot.png";

const Unsubscribe = () => {
  const [hasErrors, setHasErrors] = useState(false);
  const [unsubscribeType, setUnsubscribeType] = useState("");
  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const status = urlSearchParams.get("status");
      const type = urlSearchParams.get("type");
      if (type) {
        setUnsubscribeType(SettingsMap[type]);
      }
      if (status === "error") {
        setHasErrors(true);
      }
    }
  }, []);

  return (
    <Layout
      noIndex
      title="Bidddy | Unsubscribe"
      description="Unsubscribe from Bidddy promotional emails"
    >
      <div className={styles.unsubscribeContainer}>
        {!hasErrors && (
          <>
            <Imgix
              src={`${ConfirmationImage}?auto=format`}
              height={699}
              width={553}
              htmlAttributes={{
                alt: "Confirmation",
                src: `${ConfirmationImage}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
            <h1>Unsubscribed</h1>
            <p>
              You have successfully unsubscribed from Bidddy&apos;s{" "}
              {unsubscribeType} Notifications. If you would like to adjust your
              notification settings you can do so from your account{" "}
              <Link to="/dashboard/account/notification-settings/">here</Link>.
            </p>
          </>
        )}
        {hasErrors && (
          <>
            <h1>Uh Oh!</h1>
            <p>
              There was an error on our end with unsubscribing. We have been
              notified and will fix the issue. Please try again later.
            </p>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Unsubscribe;
